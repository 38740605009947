











import { Component, Vue, Watch } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import { getNewDetail } from '../../../api'


@Component({
  components: { vFreeApply }
})
export default class extends Vue {
  private info = {
    title: '',
    pubDate: '',
    content: '',
    editor: ''
  }

  get content(){
    return this.info.content.replace(/src="\/uploads\//g, 'src="http://xmheigu.com/uploads/')
  }

  async getDetail(id:string){
    const info = await getNewDetail(id)
    this.info = info
  }

  @Watch('$route')
  routechange(to: any) {
    this.getDetail(to.params.id)
  }

  mounted(){
    this.getDetail(this.$route.params.id)
  }
}
